@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
$body-bg: #fff;
$primary: #87972f;
$dark: #373637;
$border-radius: 3px;
$euiColorPrimary: #87972f;

$font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";


//@import '~@elastic/eui/dist/eui_theme_light.css';
@import '~@elastic/eui/src/themes/amsterdam/theme_light';
//@import '~bootstrap/dist/css/bootstrap.min.css';
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';


:root {
  --inkydark: #373637;
  --offwhite: #f9f3f2;
  --white: #f9f3f2;
  --lightgreen: #CDEAE5;
  --normalgreen: #A8D4CC;
  --purple: #5F5283;
  --darkgray: #9B9A9B;

}

html {
  background-color: var(--bs-body-bg) !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

::selection {
  background-color: var(--lightgreen);
}

#root {

}

footer {
  background-color: var(--bs-body-bg);
  @media (min-width: 1440px) {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
}

.nav-pills .nav-link {
  font-size: 1.1rem;
  color: var(--gray);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--primary);
  background-color: var(--white);
  border-bottom: 4px solid var(--primary);
  border-radius: 0;
  font-weight: 700;
}
.btn-primary, .btn-primary:hover{
  color: var(--offwhite);
}



svg {
  overflow: visible;
}

.form-control::placeholder {
  color: #dfe7da !important;
  opacity: 1;
}

.form-control {
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 0.5;
}

.react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content, .react-contexify__item:not(.react-contexify__item--disabled):focus > .react-contexify__item__content {
  color: var(--dark) !important;
  background-color: white !important;
}

.react-contexify__submenu--is-open, .react-contexify__submenu--is-open > .react-contexify__item__content {
  color: var(--dark) !important;
  background-color: white !important;
}

.euiRangeTick {
  border-width: 0;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 400px;
  }
}